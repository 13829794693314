import { Html } from '@react-three/drei'
import { Html as HtmlDepth } from './depth/Html'
import YouTube from 'react-youtube'
import { DoubleSide } from 'three'
import '../styles/youtube.css'
import useVideoManager from '../hooks/useVideoManager'
import { isMobile } from 'react-device-detect'
import { Vector3 } from 'three'
import useUIManager from '../hooks/useUIManager'
import { useEffect, useState, useRef } from 'react'
import { browserName } from 'react-device-detect'
import { useFrame } from '@react-three/fiber'

function StreamSafari(props) {
  const { url, scale } = props
  const id = url.split('v=')[1].split('&')[0]

  const setVideo = useVideoManager((state) => state.setLoaded)
  const loaded = useVideoManager((state) => state.didLoad.video)
  const setResource = useUIManager((state) => state.resourceLoaded)
  const set = useVideoManager((state) => state.set)

  useEffect(() => {
    setResource('video', true)
  }, [loaded, setResource])

  function onPlayerChange(event) {
    const bytes = event.target.getVideoLoadedFraction()
    if (bytes > 0 && !loaded) {
      setVideo('video', true)
    }
  }
  function onPlayerError(event) {
    console.log('error', event)
  }

  function onPlayerReady(event) {
    set('player', event.target)
    if (isMobile && !loaded) {
      setVideo('video', true)
    }
  }

  const opts = {
    height: '390',
    width: '640',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      controls: 0,
      rel: 0,
      loop: 1,
      autoplay: 1,
      mute: 1,
    },
  }
  const videoVisible = useVideoManager((state) => state.visible.video)

  const meshRef = useRef()

  const [isOccluded, setOccluded] = useState()
  const [isInRange, setInRange] = useState()
  const isVisible = isInRange && !isOccluded

  const vec = new Vector3()
  useFrame((state) => {
    const range =
      state.camera.position.distanceTo(meshRef.current.getWorldPosition(vec)) <=
      4
    if (range !== isInRange) setInRange(range)
  })

  return (
    <>
      <mesh ref={meshRef} {...props}>
        <Html
          className={videoVisible ? 'content' : 'hide'}
          scale={scale}
          occlude
          // Tells us when contents are occluded (or not)
          onOcclude={setOccluded}
          style={{
            transition: 'all 0.2s',
            opacity: isVisible ? 1 : 0.5,
          }}
          transform
          material={<meshStandardMaterial side={DoubleSide} />}
        >
          <YouTube
            videoId={id}
            opts={opts}
            onReady={onPlayerReady}
            onStateChange={onPlayerChange}
            onError={onPlayerError}
          />
        </Html>
      </mesh>
    </>
  )
}

function StreamDefault(props) {
  const { url, scale } = props
  const id = url.split('v=')[1].split('&')[0]

  const setVideo = useVideoManager((state) => state.setLoaded)
  const loaded = useVideoManager((state) => state.didLoad.video)
  const setResource = useUIManager((state) => state.resourceLoaded)
  const set = useVideoManager((state) => state.set)
  useEffect(() => {
    setResource('video', true)
  }, [loaded, setResource])

  function onPlayerChange(event) {
    //const time = event.target.getCurrentTime()
    const bytes = event.target.getVideoLoadedFraction()
    if (bytes > 0 && !loaded) {
      setVideo('video', true)
    }
  }
  function onPlayerError(event) {
    console.log('error', event)
  }

  function onPlayerReady(event) {
    set('player', event.target)

    if (isMobile && !loaded) {
      setVideo('video', true)
    }
  }

  const opts = {
    height: '390',
    width: '640',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      controls: 0,
      rel: 0,
      loop: 1,
    },
  }
  const videoVisible = useVideoManager((state) => state.visible.video)

  return (
    <>
      <mesh {...props}>
        <HtmlDepth
          className={videoVisible ? 'content' : 'hide'}
          scale={scale}
          transform
          depthTest
          material={<meshStandardMaterial side={DoubleSide} />}
        >
          <YouTube
            videoId={id}
            opts={opts}
            onReady={onPlayerReady}
            onStateChange={onPlayerChange}
            onError={onPlayerError}
          />
        </HtmlDepth>
      </mesh>
    </>
  )
}

const Stream = browserName.includes('Safari') ? StreamSafari : StreamDefault
export default Stream
