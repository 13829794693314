import create from 'zustand'

const useAudioManager = create((set) => ({
  didLoad: {
    audio: false,
  },
  audiosRef: {
    audio: null,
  },
  allRefs: false,
  allLoaded: false,
  setLoaded: (type, loaded) =>
    set((state) => {
      const newState = {
        didLoad: { ...state.didLoad, [type]: loaded },
      }

      const v = Object.values(newState.didLoad).every(Boolean)

      return { ...state, ...newState, allLoaded: v }
    }),
  setAudioRef: (type, ref) =>
    set((state) => {
      const newState = {
        audiosRef: { ...state.audiosRef, [type]: ref },
      }
      const v = Object.values(newState.audiosRef).every(Boolean)

      return { ...state, ...newState, allRefs: v }
    }),
}))

export default useAudioManager
