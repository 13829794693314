import { useEffect, useState, useMemo } from 'react'
import { useThree } from '@react-three/fiber'
import { Vector2, Vector3 } from 'three'
//import PointerLockControls from './PointerLockControls'
import { OrbitControls } from '@react-three/drei'

function DoubleTapManager({ onSuccess }) {
  const INTERVAL = 300
  const THRESHOLD = 9
  const TIME = 280

  const INITSTATE = {
    previousTime: 0,
    previousCenter: null,
    count: 0,
    timer: null,
  }

  var state = INITSTATE
  const restart = () => {
    clearTimeout(state.timer)
    state = INITSTATE
  }

  const setFirstEvent = (event) => {
    const { clientX, clientY } = event.targetTouches[0]
    const _timer = setTimeout(restart, TIME)

    state = {
      previousTime: event.timeStamp,
      count: 1,
      previousCenter: new Vector2(clientX, clientY),
      timer: _timer,
    }
  }

  this.processTap = (event) => {
    if (!state.previousTime) {
      setFirstEvent(event)
    } else if (state.count === 1) {
      const { clientX, clientY } = event.targetTouches[0]

      const validTime = event.timeStamp - state.previousTime < INTERVAL
      const validTap =
        Math.abs(clientX - state.previousCenter.x) < THRESHOLD &&
        Math.abs(clientY - state.previousCenter.y) < THRESHOLD

      if (validTime && validTap) {
        onSuccess()
        restart()
      }
    }
  }
}

const INCREMENT = 1
function MobileViewer({ callback }) {
  const { camera } = useThree()

  const [movement, setMovement] = useState({
    moveForward: 1,
    moveBackward: 0,
    moveLeft: 0,
    moveRight: 0,
  })

  useMemo(() => {
    if (movement.moveForward > 0) {
      var { moveForward, moveBackward, moveLeft, moveRight } = movement

      const direction = new Vector3()

      const frontVector = new Vector3(
        0,
        0,
        Number(moveBackward) - Number(moveForward),
      )
      const sideVector = new Vector3(Number(moveLeft) - Number(moveRight), 0, 0)

      direction.subVectors(frontVector, sideVector).normalize()

      camera.getWorldDirection(direction)
      camera.position.addScaledVector(direction, INCREMENT)
      setMovement((state) => ({ ...state, moveForward: 0 }))
      callback(false)
    }
  }, [movement, camera, callback])

  useEffect(() => {
    const handleDoubleTap = () => {
      setMovement((state) => ({ ...state, moveForward: INCREMENT }))
      callback(true)
    }
    const doubleTapManager = new DoubleTapManager({
      onSuccess: handleDoubleTap,
    })

    const handler = (e) => doubleTapManager.processTap(e)

    const canvas = document.getElementsByTagName('Canvas')[0]
    canvas.addEventListener('touchstart', handler, { passive: true })

    return () => {
      canvas.removeEventListener('touchstart', handler, { passive: true })
    }
  })

  return (
    <>
      <OrbitControls />
    </>
  )
}

export default MobileViewer
